import { useCallback, useEffect } from "react";

export const useBeforeUnload = (message: string) => {
  const handleBeforeUnload = useCallback(
    (event) => {
      console.log(event);
      event.preventDefault();
      event.returnValue = message;
    },
    [message]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  });
};
