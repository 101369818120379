import { makeStyles, Typography } from "@material-ui/core";
import React, { FC } from "react";

const baseSize = "1.5em";
const useStyles = makeStyles((theme) => ({
  root: {
    width: baseSize,
    height: baseSize,
    textAlign: "center",
    borderRadius: "50%",
    lineHeight: baseSize,
    boxSizing: "content-box",
    fontSize: "1rem",
    display: "inline-block",
    background: theme.palette.primary.main,
    border: `0.15em solid ${theme.palette.primary.main}`,

    [theme.breakpoints.down("xs")]: {
      border: "none",
      borderRadius: 0,
      height: "auto",
      width: "auto",
      lineHeight: "1.25em",
    },
  },
}));

type Props = {
  /**
   * Number of the bullet
   */
  index: number;
};

export const NumberedBullet: FC<Props> = ({ index }) => {
  const classes = useStyles();
  return (
    <Typography variant="body1" className={classes.root}>
      {index}
    </Typography>
  );
};
