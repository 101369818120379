import { Box, Fade, Paper, Typography, Zoom } from "@material-ui/core";
import DOMPurify from "dompurify";
import React from "react";
import { CreateCVIcon } from "../../atoms/icon/CreateCVIcon"
import { OpenCVIcon } from "../../atoms/icon/OpenCVIcon";
import styles from "./PreviewPanel.module.css";

export const PreviewPanel = ({ preview }) => {
  if (!preview) {
    return (
      <Fade in={true} timeout={1000}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className={styles.content}>
          <Typography variant="h4" className={styles.title}>No preview available yet</Typography>
          <Typography variant="subtitle1" className={styles.subtitle}>Open an existing CV by clicking <OpenCVIcon className={styles.icon} /> or just drag it on top of the editor. To create a new CV click{" "}
            <CreateCVIcon className={styles.icon} /> to start with a template.</Typography>
        </Box>
      </Fade>
    );
  }

  return (
    <Zoom in={true} timeout={300}>
      <Paper elevation={2} className={styles["preview-panel"]} display="flex">
        <iframe title="CV preview" srcDoc={sanitizeDOM(preview)} className={styles["preview-frame"]}></iframe>
      </Paper>
    </Zoom>
  );
};

const sanitizeDOM = (domString) => {
  return DOMPurify.sanitize(domString, {
    FORCE_BODY: true,
    WHOLE_DOCUMENT: true,
    ADD_TAGS: ["link"],
    ADD_ATTR: ["target"],
  });
};
