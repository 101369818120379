import { IconButton, Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSnackbar } from "./useSnackbar";

export const SnackBar = ({ message }) => {
  const { messageInfo, addSnack, closeSnack, exitSnack, open } = useSnackbar();
  useEffect(() => {
    if (message && message.content) {
      addSnack(message.content);
    }
  }, [addSnack, message]);

  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      autoHideDuration={5000}
      onClose={closeSnack}
      onExited={exitSnack}
    >
      <Alert
        severity={message && message.severity}
        action={
          <React.Fragment>
            <IconButton aria-label="close" color="inherit" onClick={closeSnack}>
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      >
        {messageInfo ? messageInfo.message : ""}
      </Alert>
    </Snackbar>
  );
};

Snackbar.propTypes = {
  message: PropTypes.objectOf({
    content: PropTypes.string,
    severity: PropTypes.string,
  }),
};
