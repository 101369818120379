import { IconButton, PropTypes, Tooltip } from "@material-ui/core";
import React, { FC } from "react";

type Props = {
  onClick: (...args: any[]) => void;
  tooltip: string;
  icon: JSX.Element;
  color?: PropTypes.Color;
  disabled?: boolean;
};

export const TooltipIconButton: FC<Props> = ({ onClick, tooltip, icon, color = "default", disabled }) => {
  return (
    <Tooltip title={tooltip}>
      <span>
        <IconButton aria-label="open" onClick={onClick} color={color} disabled={disabled}>
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  );
};
