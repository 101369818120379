/**
 * A hook which downloads a blob as a file
 */
export const useDownloadFile = () => {
  const download = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    let anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = url;
    anchor.download = filename;
    anchor.click();
  };

  return { download };
};
