import { Box, Container } from "@material-ui/core";
import React from "react";
import { MainAppBar } from "../../organisms/main-app-bar/MainAppBar";

export const FrameTemplate = ({ children, title }) => {
  return (
    <>
      <MainAppBar title={title}></MainAppBar>
      <Container maxWidth={false} disableGutters>
        <Box my={1}>{children}</Box>
      </Container>
    </>
  );
};
