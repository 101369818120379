import { cvgenAPI } from "./axios";

export const postCVYaml = (yaml, portrait, queryParams) => {
  const formData = new FormData();
  var blob = new Blob([yaml], { type: "text/yaml" });
  formData.append("file", blob);

  if (portrait) {
    formData.append("portrait", portrait);
  }

  // append query params to form data
  Object.entries(queryParams).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return cvgenAPI.post(`/cv`, formData, {
    responseType: queryParams.resultFormat === "pdf" ? "blob" : "text",
  });
};

export const getCVTemplate = async () => {
  return await cvgenAPI.get("/cv/template");
};
