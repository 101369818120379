import { cvgenAPI } from "./axios";

export const getCurrentUser = () => {
  return cvgenAPI.get("/_gcp_iap/identity");
};

export const logout = () => {
  // TODO: add routing at some point
  document.location.href = "/logout";
};
