import { useMediaQuery, useTheme } from "@material-ui/core";
import React, { createContext, FC, useCallback, useContext, useState } from "react";

type EditorContextValues = {
  previewHidden: boolean;
  togglePreviewVisibility: () => void;
};

const EditorContext = createContext<EditorContextValues>({} as EditorContextValues);

export const EditorProvider: FC<{}> = ({ children }) => {
  const theme = useTheme();
  const previewInitiallyHidden = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  const [previewHidden, setPreviewHidden] = useState<boolean>(previewInitiallyHidden);
  const handleTogglePreviewVisibility = useCallback(() => {
    setPreviewHidden(!previewHidden);
  }, [previewHidden]);

  return (
    <EditorContext.Provider value={{ previewHidden: previewHidden, togglePreviewVisibility: handleTogglePreviewVisibility }}>{children}</EditorContext.Provider>
  );
};

export const useEditorContext = (): EditorContextValues => useContext(EditorContext);
