import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { useCallback, useEffect, useMemo } from "react";
import { Dialog } from "../../components/atoms/dialog/Dialog";
import { CreateCVIcon } from "../../components/atoms/icon/CreateCVIcon";
import { DownloadFileIcon } from "../../components/atoms/icon/DownloadFileIcon";
import { OpenCVIcon } from "../../components/atoms/icon/OpenCVIcon";
import { NumberedList } from "../../components/organisms/numbered-list/NumberedList";
import { getModalStateById, hideModal, showModal, useModalContext } from "../../context/modal/ModalProvider";
import { PreferenceNames, useUserPreference } from "../../hooks/useUserPreference";

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: "middle",
    fontSize: "1.25rem",
  },
  info: {
    fontWeight: 700,
  },
}));

export const MODAL_ID = "modal-welcome";
export const HelpModal = () => {
  const classes = useStyles();
  const { dispatch, state } = useModalContext();
  const { getPreference, savePreference } = useUserPreference(PreferenceNames.WELCOME_MODAL_VIEWED);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const isOpen = useMemo(() => {
    return getModalStateById(state, MODAL_ID);
  }, [state]);

  useEffect(() => {
    (async () => {
      const welcomeModalViewed = await getPreference();
      if (!Boolean(welcomeModalViewed)) {
        dispatch(showModal({ id: MODAL_ID }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = useCallback(() => {
    dispatch(hideModal({ id: MODAL_ID }));
    savePreference(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listItems = useMemo(() => {
    return [
      <>
        Open an existing CV by clicking <OpenCVIcon className={classes.icon} /> or just drag it on top of the editor. To create a new CV click{" "}
        <CreateCVIcon className={classes.icon} /> to start with a template. We recommend copy-pasting that content to your
        favorite text editor and making any changes there.
      </>,
      <>
        To see real-time preview, paste changes to the YAML editor and make any necessary adjustments. To generate a PDF click{" "}
        <DownloadFileIcon className={classes.icon} />.
      </>,
      <>
        Once you're happy with it, click <DownloadFileIcon className={classes.icon} /> to save the CV in YAML format and store it in a safe place.{" "}
        <span className={classes.info}>The service won't keep it safe for you*, at least for now.</span>

        <p><small>
        *) YAML is stored only in your browser's local storage, which is why we recommend using an external editor
        and store it somewhere safe for now</small>
        </p>
      </>
    ];
  }, [classes.icon, classes.info]);

  return (
    <Dialog title="Welcome" closeButtonText="OK, let's go!" isOpen={isOpen} onClose={handleClose} fullScreen={fullScreen}>
      <NumberedList items={listItems} />
    </Dialog>
  );
};
