import { AppBar,  makeStyles, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import { UserMenu } from "../../../containers/user-menu/UserMenu";
import logo from "../../../logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
  },
  logo: {
    height: theme.typography.h3.fontSize,
    margin: "1.5rem 3rem 1.5rem 0.5rem",
  },
  title: {
    flexGrow: 1,
  },
}));

type MainAppBarProps = {
  title: string;
};

export const MainAppBar = ({ title }: MainAppBarProps) => {
  const classes = useStyles();
  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <img src={logo} alt="logo" className={classes.logo} />
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};
