import { makeStyles } from "@material-ui/core";
import React, { FC, useCallback } from "react";
import { Dialog } from "../../components/atoms/dialog/Dialog";
import { useEditorContext } from "../../context/editor/EditorProvider";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
}));

export const PreviewModal: FC<{}> = ({ children }) => {
  const classes = useStyles();
  const { previewHidden, togglePreviewVisibility } = useEditorContext();
  const handleClose = useCallback(() => {
    togglePreviewVisibility();
  }, [togglePreviewVisibility]);
  return (
    <Dialog title="Preview" isOpen={!previewHidden} onClose={handleClose} contentClassName={classes.content} fullScreen>
      {children}
    </Dialog>
  );
};
