import { useUserPreference } from "./useUserPreference";

const CV_KEY = "cv";
export const useCV = () => {
  const { getPreference, savePreference } = useUserPreference(CV_KEY);

  return {
    getCV: getPreference,
    saveCV: savePreference,
  };
};
