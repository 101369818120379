import React, { useCallback, useEffect, useState } from "react";
import { resizeImageFile } from "../../../utils/image-resize";
import { DropZoneArea } from "../../molecules/dropzone-area/DropZoneArea";

const IMAGE_MAX_WIDTH = 500;
const IMAGE_MAX_HEIGHT = 500;

export const PortraitForm = ({ onPortraitAdded, onPortraitRemoved, portraitFile }) => {
  const [portrait, setPortrait] = useState();

  const handleFileDrop = useCallback(
    (files) => {
      files.forEach(async (file) => {
        try {
          const resizedFile = await resizeImageFile({
            file,
            targetWidth: IMAGE_MAX_WIDTH,
            targetHeight: IMAGE_MAX_HEIGHT,
          });
          onPortraitAdded(resizedFile);
        } catch (error) {
          console.error("Something went wrong while resizing image", error);
        }
      });
    },
    [onPortraitAdded]
  );

  const handlePortraitRemove = useCallback(() => {
    onPortraitRemoved();
    setPortrait();
  }, [onPortraitRemoved, setPortrait]);

  useEffect(() => {
    let url;
    if (portraitFile) {
      url = URL.createObjectURL(portraitFile);
      setPortrait(url);
    }
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [portraitFile]);
  return (
    <>
      <DropZoneArea
        onDrop={handleFileDrop}
        accept={"image/png, image/jpg, image/jpeg"}
        dragInactiveLabel="Drop a portrait image here or click me to pick one from the file system!"
        preview={portrait}
        onRemove={handlePortraitRemove}
      />
    </>
  );
};
