/**
 * Resizes an image file to target width and height maintaining aspect ratio
 *
 * @param {Object} options - Options object
 * @param {File} options.file - image file to resize
 * @param {File} options.targetWidth - target width for the resized image
 * @param {File} options.targetHeight - target height for the resized image
 */
export const resizeImageFile = ({ file, targetWidth, targetHeight }) => {
  return new Promise((resolve, reject) => {
    console.log("Size before resize:", file.size);
    const fileName = file.name;
    const mimeType = file.type;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const { width, height } = calculateAspectRatioFit(
          img.width,
          img.height,
          targetWidth,
          targetHeight
        );
        const elem = document.createElement("canvas");
        elem.width = width;
        elem.height = height;
        const ctx = elem.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        ctx.canvas.toBlob(
          (blob) => {
            const resizedFile = new File([blob], fileName, {
              type: mimeType,
              lastModified: Date.now(),
            });
            console.log("Size after resize:", resizedFile.size);
            resolve(resizedFile);
          },
          mimeType,
          0.9
        );
      };
      img.onerror = (error) => {
        reject(error);
      };
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
};
