import { FormControl, TextField } from "@material-ui/core";
import React, { useCallback, useState } from "react";

export const InputText = ({
  helperText,
  label,
  placeholder,
  name,
  onChange,
  onKeyUp,
  fullWidth,
  disableUnderline,
}) => {
  const [value, setValue] = useState("");
  const handleChange = useCallback(
    (event) => {
      setValue(event.target.value);
      onChange({
        name,
        value: event.target.value,
      });
    },
    [setValue, onChange, name]
  );

  const handleKeyUp = useCallback(
    (event) => {
      const { keyCode } = event;
      onKeyUp({ keyCode });
    },
    [onKeyUp]
  );

  return (
    <FormControl fullWidth={fullWidth}>
      <TextField
        onKeyUp={handleKeyUp}
        name={name}
        onChange={handleChange}
        value={value}
        label={label}
        placeholder={placeholder}
        helperText={helperText}
        InputProps={{ disableUnderline }}
      />
    </FormControl>
  );
};
