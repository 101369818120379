import { CssBaseline, ThemeProvider } from "@material-ui/core";
import React from "react";
import "./App.css";
import { CVEditor } from "./components/pages/cv-editor/CVEditor";
import { AuthProvider } from "./context/auth/AuthProvider";
import { EditorProvider } from "./context/editor/EditorProvider";
import { ModalProvider } from "./context/modal/ModalProvider";
import { theme } from "./theme/theme";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CssBaseline>
          <EditorProvider>
            <ModalProvider>
              <CVEditor />
            </ModalProvider>
          </EditorProvider>
        </CssBaseline>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
