import { Fade, Hidden, makeStyles } from "@material-ui/core";
import React from "react";
import { PreviewModal } from "../../../containers/preview-modal/PreviewModal";
import { useResize } from "../../../hooks/useResize";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    // full window height minus app bar and margin height
    height: "calc(100vh - 66px - 8px - 8px)",
  },
  editor: {
    flexGrow: 1,
    flexBasis: "33%",
    margin: theme.spacing(2),
    zIndex: 1,
  },

  preview: {
    transition: "all 0.3s",
    flexGrow: 0,
    maxWidth: "210mm",
    flexBasis: "210mm",
    margin: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    opacity: 1,
  },
  hidden: { maxWidth: 0, margin: 0, opacity: 0 },
}));

export const EditorTemplate = ({ editor, preview, previewHidden }) => {
  const classes = useStyles();
  const { forceResize } = useResize();
  const previewClassNames = previewHidden ? classes.hidden : "";
  return (
    <div className={classes.container}>
      <div className={classes.editor}>{editor}</div>
      <Hidden smDown>
        <div className={`${previewClassNames} ${classes.preview}`}>
          <Fade in={!previewHidden} timeout={300} onExited={forceResize} onEntered={forceResize}>
            {preview}
          </Fade>
        </div>
      </Hidden>
      <Hidden mdUp>
        <PreviewModal>{preview}</PreviewModal>
      </Hidden>
    </div>
  );
};
