import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { DropZone } from "../../atoms/drop-zone/DropZone";

const useStyles = makeStyles((theme) => ({
  editor: {
    "&::after": {
      content: "''",
      transition: "transform 100ms",
      transform: "scale(0)",
    },
    position: "relative",

    "&.dragging::after": {
      position: "absolute",
      height: "100%",
      width: "100%",
      background: "rgba(255,255,255,0.75)",
      color: theme.palette.primary.main,
      content: "'Now drop it like\\a it\\'s hot'",
      whiteSpace: "pre",
      top: 0,
      left: 0,
      fontSize: "2rem",
      textAlign: "center",
      paddingTop: "50%",
      transition: "transform 100ms",
      transform: "scale(1)",
      zIndex: 1000,
    },
  },
}));

type Props = {
  onDrop: (files: any[]) => void;
  accept: string;
};

export const DropZoneDraggable: FC<Props> = ({ children, onDrop, accept }) => {
  const classes = useStyles();
  return (
    <DropZone noClick onDrop={onDrop} accept={accept}>
      {({ isDragActive }) => <div className={`${classes.editor} ${isDragActive ? "dragging" : ""}`}>{children}</div>}
    </DropZone>
  );
};
