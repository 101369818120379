import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import React, { useCallback } from "react";

export const InputCheckbox = ({
  color = "primary",
  initialValue,
  label,
  name,
  onChange,
}) => {
  const [checked, setChecked] = React.useState(initialValue);

  const handleChange = useCallback(
    (event) => {
      setChecked(event.target.checked);
      onChange({
        name,
        value: event.target.checked,
      });
    },
    [onChange, setChecked, name]
  );

  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          checked={checked}
          onChange={handleChange}
          color={color}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      }
      label={label}
    />
  );
};
