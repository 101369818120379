import { Divider, ListItem, ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HelpIcon from "@material-ui/icons/Help";
import MeetingRoomOutlinedIcon from "@material-ui/icons/MeetingRoomOutlined";
import React, { useCallback, useState } from "react";
import { TooltipIconButton } from "../../components/atoms/tooltip-icon-button/TooltipIconButton";
import { AuthContextValues, useAuth } from "../../context/auth/AuthProvider";
import { showModal, useModalContext } from "../../context/modal/ModalProvider";
import { MODAL_ID as WELCOME_MODAL_ID } from "../help-modal/HelpModal";

export const UserMenu = () => {
  const { user, logout }: AuthContextValues = useAuth();
  const { dispatch } = useModalContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleHelpClick = useCallback(() => {
    dispatch(showModal({ id: WELCOME_MODAL_ID }));
    setAnchorEl(null);
  }, [dispatch]);

  return (
    <>
      <TooltipIconButton tooltip="Account" icon={<AccountCircleIcon />} onClick={handleMenuClick} color="inherit" />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <ListItem>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary={user.isLoggedIn ? `${user.email}` : "Not logged in"} />
        </ListItem>
        <Divider />
        <MenuItem onClick={handleHelpClick}>
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary={"Help"} />
        </MenuItem>
        {user.isLoggedIn && (
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <MeetingRoomOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
