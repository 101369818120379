import { Grid } from "@material-ui/core";
import React from "react";
import { InputCheckbox } from "../../atoms/input-checkbox/InputCheckbox";
import { InputSelect } from "../../atoms/input-select/InputSelect";

const gradeOptions = [
  {
    name: "1 - Novice",
    value: 1,
  },
  {
    name: "2 - Basic",
    value: 2,
  },
  {
    name: "3 - Proficient",
    value: 3,
  },
  {
    name: "4 - Expert",
    value: 4,
  },
  {
    name: "5 - Master",
    value: 5,
  },
];

const templateNameOptions = [
  {
    name: "Contrivance",
    value: "contrivance",
  },
  {
    name: "Brainchild",
    value: "brainchild",
  },
  {
    name: "Innovation",
    value: "innovation",
  },
];

export const CVEngineControlForm = ({ onChange, values }) => {
  const handleChange = ({ name, value }) => {
    const newState = {
      ...values,
      [name]: value,
    };
    onChange(newState);
  };

  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputSelect
            name="templateName"
            options={templateNameOptions}
            label="Template name"
            helperText="Template to use for CV rendering"
            initialValue={values.templateName}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputSelect
            name="skillGradeThreshold"
            options={gradeOptions}
            label="Skill grade"
            helperText="Hide skills below this grade"
            initialValue={values.skillGradeThreshold}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputCheckbox
            name="includeSocialLinks"
            label="Include social links"
            initialValue={values.includeSocialLinks}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </form>
  );
};
