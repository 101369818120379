import { Backdrop, CircularProgress } from "@material-ui/core";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { getCurrentUser, logout } from "../../api/user";

export type AuthContextValues = {
  user: IAuthUser;
  logout: (e: SyntheticEvent) => void;
};

class AuthUser implements IAuthUser {
  private user;
  constructor(user?: GCP_IAP_User) {
    this.user = user;
  }

  get isLoggedIn() {
    return !!this.subject;
  }

  get subject() {
    return this.user?.sub;
  }

  get email() {
    return this.user?.email?.split(":")[1] || this.user?.email;
  }
}

export interface IAuthUser {
  isLoggedIn: boolean;
  subject?: string;
  email?: string;
}

type GCP_IAP_User = {
  sub: string;
  email: string;
};

//const initialContextValues : AuthContextValues = {user: new AuthUser(), logout: () => {}}
const AuthContext = React.createContext({ user: new AuthUser(), logout: () => {} } as AuthContextValues);

type AuthProviderProps = {
  children: any;
  onLogout: Function;
  onGetUser: Function;
};

/**
 * Provider for auth related functions
 * @param {Object} props
 * @param {Object} props.children child node(s) passed to component
 * @param {Function} props.onLogout callback for logout event
 * @param {Function} props.onGetUser callback for fetching user data event
 */
export const AuthProvider = ({ children, onLogout = logout, onGetUser = getCurrentUser }: AuthProviderProps) => {
  const [user, setUser] = useState(new AuthUser());
  const [loading, setLoading] = useState(false);

  const handleLogout = useCallback(() => {
    onLogout();
  }, [onLogout]);

  useEffect(() => {
    setLoading(true);
    onGetUser()
      .then((response: any) => {
        const authUser = new AuthUser(response);
        if (authUser.isLoggedIn) {
          setLoading(false);
          setUser(authUser);
        } else {
          handleLogout();
        }
      })
      .catch((error: any) => {
        setLoading(false);
        console.error("Could not retrieve user data", error);
      });
  }, [onGetUser, setUser, setLoading, handleLogout]);

  if (loading) {
    return (
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  const contextValue: AuthContextValues = { user, logout: handleLogout };
  return <AuthContext.Provider value={contextValue as AuthContextValues}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextValues => React.useContext(AuthContext);
