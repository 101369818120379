import { Box, makeStyles, Typography, Zoom } from "@material-ui/core";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import React, { FC, useCallback } from "react";
import { DropZone } from "../../atoms/drop-zone/DropZone";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    padding: "3em",
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: theme.palette.primary.main,
    borderRadius: "1em",
    "&:active, &:focus": {
      borderStyle: "solid",
      outline: "none",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon: {
    fontSize: 80,
  },
  text: {
    paddingLeft: theme.spacing(2),
  },
}));

type Props = {
  accept: string;
  onDrop: () => void;
  onRemove: () => void;
  dragActiveLabel?: string;
  dragInactiveLabel?: string;
  preview?: string;
};

/**
 * DropZone component receives file(s) dropped in its area and optionally shows a preview of it.
 *
 * @param {Object} options - options object
 * @param {string} options.accept - file extensions or mime types accepted by the drop zone
 * @param {function} options.onDrop - callback when a file is dropped or selected in the drop zone
 * @param {function} options.onRemove - callback when a file is removed from drop zone
 * @param {string} options.dragActiveLabel - text shown when the drag area is active
 * @param {string} options.dragInactiveLabel - text shown when the drag area is inactive
 * @param {string} options.preview - base64 encoded preview
 */
export const DropZoneArea: FC<Props> = ({ accept, onDrop, onRemove, dragActiveLabel, dragInactiveLabel, preview }) => {
  const classes = useStyles();
  return (
    <DropZone accept={accept} onDrop={onDrop}>
      {({ isDragActive }) => (
        <Box display="flex" flexDirection="row" alignItems="center" className={classes.dropzone}>
          {preview ? <ImagePreview preview={preview} onRemove={onRemove} /> : <DescriptionOutlinedIcon color="primary" className={classes.icon} />}
          <Typography variant="body2" className={classes.text}>
            {isDragActive ? dragActiveLabel : dragInactiveLabel}
          </Typography>
        </Box>
      )}
    </DropZone>
  );
};

DropZoneArea.defaultProps = {
  dragInactiveLabel: "Drop a file here or click me!",
  dragActiveLabel: "Now drop it here...",
};

const useImagePreviewStyles = makeStyles((theme) => ({
  img: {
    width: 80,
    height: 80,
    objectFit: "cover",
  },
  previewContainer: {
    position: "relative",
  },
  deleteIcon: {
    position: "absolute",
    background: theme.palette.background.default,
    borderRadius: "1em",
    top: -10,
    right: -10,
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(0.75)",
    },
  },
}));

type ImagePreviewProps = {
  preview?: string;
  onRemove: () => void;
};

const ImagePreview: FC<ImagePreviewProps> = ({ preview, onRemove }) => {
  const classes = useImagePreviewStyles();
  const handleRemove = useCallback(
    (event) => {
      event.stopPropagation();
      onRemove();
    },
    [onRemove]
  );

  return (
    <Zoom in={!!preview} timeout={500}>
      <div className={classes.previewContainer}>
        <HighlightOffRoundedIcon color="primary" className={classes.deleteIcon} onClick={handleRemove} />
        <img src={preview} className={classes.img} alt="Preview" />
      </div>
    </Zoom>
  );
};
