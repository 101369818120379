import { Divider } from "@material-ui/core";
import { Ace } from "ace-builds";
import React, { FC, useCallback, useState } from "react";
import { getCVTemplate } from "../../../api/cv";
import { useEditorContext } from "../../../context/editor/EditorProvider";
import { useDownloadFile } from "../../../hooks/useDownloadFile";
import { TextEditor } from "../../atoms/text-editor/TextEditor";
import { DropZoneDraggable } from "../../molecules/drop-zone-draggable/DropZoneDraggable";
import { EditorToolbar } from "../editor-toolbar/EditorToolbar";

type Props = {
  onEditorChange: (value: string) => void;
  onPDFDownload: () => Promise<any>;
  editorPlaceholder: string;
  editorValue: string;
};

export const EditorContainer: FC<Props> = ({ onEditorChange, onPDFDownload, editorPlaceholder, editorValue }) => {
  const [editorInstance, setEditorInstance] = useState<Ace.Editor>();
  const { download } = useDownloadFile();
  const { previewHidden, togglePreviewVisibility } = useEditorContext();

  const handleEditorLoad = useCallback(
    (editorInstance) => {
      setEditorInstance(editorInstance);
    },
    [setEditorInstance]
  );

  const handleEditorChange = useCallback(
    (value) => {
      onEditorChange(value);
    },
    [onEditorChange]
  );

  const handleFileOpen = useCallback(
    (files) => {
      files.forEach((file: File) => {
        const reader = new FileReader();
        reader.onabort = (e) => console.error("File reading aborted", e);
        reader.onerror = (e) => console.error("File reading error", e);
        reader.onload = () => {
          const result = reader.result;
          handleEditorChange(result);
        };

        reader.readAsText(file);
      });
    },
    [handleEditorChange]
  );

  const handleSearch = useCallback(
    (value) => {
      if (editorInstance) {
        // always start searching from the beginning of the current line
        const { row } = editorInstance.getCursorPosition();
        editorInstance.gotoLine(row, 0, true);
        editorInstance.find(value, {
          wrap: true,
        });
      }
    },
    [editorInstance]
  );

  const handleFindNext = useCallback(() => {
    editorInstance && editorInstance.findNext();
  }, [editorInstance]);

  const handleFindPrevious = useCallback(() => {
    editorInstance && editorInstance.findPrevious();
  }, [editorInstance]);

  const handleDownload = useCallback(() => {
    download(new Blob([editorValue]), "cv.yaml");
  }, [editorValue, download]);

  const handlePDFDownload = useCallback(() => {
    return onPDFDownload();
  }, [onPDFDownload]);

  const handleUndo = useCallback(() => {
    editorInstance && editorInstance.undo();
  }, [editorInstance]);

  const handleRedo = useCallback(() => {
    editorInstance && editorInstance.redo();
  }, [editorInstance]);

  const handleCreate = useCallback(() => {
    (async () => {
      const template = await getCVTemplate();
      handleEditorChange(template);
    })();
  }, [handleEditorChange]);

  const handleTogglePreviewVisibility = useCallback(() => {
    togglePreviewVisibility();
  }, [togglePreviewVisibility]);

  return (
    <>
      <EditorToolbar
        onSearch={handleSearch}
        onFindNext={handleFindNext}
        onFindPrevious={handleFindPrevious}
        onDownload={handleDownload}
        onPDFDownload={handlePDFDownload}
        onUndo={handleUndo}
        onRedo={handleRedo}
        onOpen={handleFileOpen}
        onCreate={handleCreate}
        onTogglePreviewVisibility={handleTogglePreviewVisibility}
        downloadDisabled={!editorValue}
        previewHidden={previewHidden}
      ></EditorToolbar>
      <Divider light></Divider>
      <DropZoneDraggable onDrop={handleFileOpen} accept={".yaml, .yml"}>
        <TextEditor
          mode="yaml"
          theme="clouds"
          name="file-edit"
          onChange={handleEditorChange}
          value={editorValue}
          placeholder={editorPlaceholder}
          width="100%"
          height="calc(100vh - 66px - 16px - 130px)"
          wrapEnabled
          showPrintMargin={false}
          tabSize={2}
          onEditorLoaded={handleEditorLoad}
        ></TextEditor>
      </DropZoneDraggable>
    </>
  );
};
