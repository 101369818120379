export class CVGenAPIError {
  constructor(data) {
    this._data = data;
  }

  get status() {
    return this._data?.status || "Unknown status";
  }

  get message() {
    return this._data?.message || "Network error";
  }
}
