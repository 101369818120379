import React, { createContext, FC, useContext, useReducer } from "react";
import { HIDE_MODAL, Modal, ModalActionTypes, ModalContextValues, ModalRecord, ModalState, SHOW_MODAL } from "./types";

// reducer
const modalReducer = (state: ModalState, action: ModalActionTypes) => {
  switch (action.type) {
    case SHOW_MODAL:
      return { ...state, [action.payload.id]: { ...action.payload, isOpen: true } };
    case HIDE_MODAL:
      return { ...state, [action.payload.id]: { ...action.payload, isOpen: false } };
    default:
      return state;
  }
};

// actions
export const showModal = (modalToShow: Modal): ModalActionTypes => ({
  type: SHOW_MODAL,
  payload: modalToShow,
});

export const hideModal = (modalToHide: Modal): ModalActionTypes => ({
  type: HIDE_MODAL,
  payload: modalToHide,
});

// context provider
const initialState = {} as ModalRecord;
const ModalContext = createContext<ModalContextValues>({} as ModalContextValues);

export const ModalProvider: FC<{}> = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);
  return <ModalContext.Provider value={{ dispatch, state }}>{children}</ModalContext.Provider>;
};

export const useModalContext = () => useContext(ModalContext);

export const getModalStateById = (state: ModalState, modalId: string): boolean => {
  return state[modalId]?.isOpen || false;
};
