import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { NumberedListItem } from "../../molecules/numbered-list-item/NumberedListItem";

const useStyles = makeStyles((theme) => ({
  item: {
    margin: theme.spacing(3, 0, 3, 0),
    "&:first-child": {
      marginTop: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
}));

type Props = {
  items: React.ReactNode[];
};

export const NumberedList: FC<Props> = ({ items }) => {
  const classes = useStyles();
  return (
    <>
      {items.map((item, index) => (
        <div className={classes.item} key={index}>
          <NumberedListItem index={index + 1}>{item}</NumberedListItem>
        </div>
      ))}
    </>
  );
};
