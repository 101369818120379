import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";

export const InputSelect = ({
  helperText,
  initialValue,
  label,
  name,
  options,
  onChange,
  fullWidth,
}) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = useCallback(
    (event) => {
      setValue(event.target.value);
      onChange({
        name,
        value: event.target.value,
      });
    },
    [setValue, onChange, name]
  );

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <Select name={name} onChange={handleChange} value={value}>
        {options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
