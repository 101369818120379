import axios from "axios";
import { CV_ENGINE_API_ROOT_URL } from "../config/app-config";
import { CVGenAPIError } from "./Error";

const axiosInstance = axios.create({
  baseURL: CV_ENGINE_API_ROOT_URL,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    if (error.response?.data instanceof Blob) {
      return new Promise((resolve, reject) => {
        error.response.data.text().then((responseText) => {
          try {
            const responseJSON = JSON.parse(responseText);
            reject(new CVGenAPIError(responseJSON));
          } catch (error) {
            reject(new CVGenAPIError());
          }
        });
      });
    } else {
      return Promise.reject(new CVGenAPIError(error.response?.data));
    }
  }
);

export const cvgenAPI = axiosInstance;
