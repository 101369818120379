import { CircularProgress, ListItemIcon, ListItemText, MenuItem } from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";

type Props = {
  onClick: (event: any) => void;
  icon: JSX.Element;
  text: string;
};

export const ProgressMenuItem: FC<Props> = ({ onClick, icon, text }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const handleClick = useCallback(
    (event) => {
      (async () => {
        setLoading(true);
        try {
          await onClick(event);
        } finally {
          setLoading(false);
        }
      })();
    },
    [onClick]
  );

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>{loading ? <CircularProgress size="1.5rem" /> : icon}</ListItemIcon>
      <ListItemText primary={text} />
    </MenuItem>
  );
};
