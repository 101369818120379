import { useCallback, useEffect, useState } from "react";

export const useSnackbar = () => {
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const addSnack = useCallback(
    (message) => {
      setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
    },
    [setSnackPack]
  );

  const closeSnack = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    },
    [setOpen]
  );

  const exitSnack = useCallback(() => {
    setMessageInfo(undefined);
  }, [setMessageInfo]);

  return { messageInfo, addSnack, closeSnack, exitSnack, open };

  /*
  const handleClick = (message) => () => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };
  */
};
