import React, { FC } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

type Props = {
  accept: string;
  onDrop: <T extends File>(acceptedFiles: T[]) => void;
  noDrag?: boolean;
  noClick?: boolean;
  children: (props: any) => any;
};

export const DropZone: FC<Props> = ({ accept, onDrop, children, noDrag, noClick }) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept,
    noDrag,
    noClick,
    multiple: false,
  } as DropzoneOptions);

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children && children({ isDragActive, open })}
    </div>
  );
};
