import React, { useCallback, useState } from "react";
import { HelpModal } from "../../../containers/help-modal/HelpModal";
import { useEditorContext } from "../../../context/editor/EditorProvider";
import { useBeforeUnload } from "../../../hooks/useBeforeUnload";
import { SnackBar } from "../../molecules/snackbar/Snackbar";
import { EditPanel } from "../../organisms/edit-panel/EditPanel";
import { PreviewPanel } from "../../organisms/preview-panel/PreviewPanel";
import { EditorTemplate } from "../../templates/editor-template/EditorTemplate";
import { FrameTemplate } from "../../templates/frame-template/FrameTemplate";

export const CVEditor = () => {
  const [preview, setPreview] = useState();
  const [message, setMessage] = useState();
  const { previewHidden } = useEditorContext();
  useBeforeUnload("Changes that you made may not be saved");

  const handlePreviewReceived = useCallback((htmldoc) => {
    setPreview(htmldoc);
  }, []);

  const handleEditorError = useCallback(
    (error) => {
      setMessage(error);
    },
    [setMessage]
  );
  return (
    <FrameTemplate title="CV Generator">
      <EditorTemplate
        editor={<EditPanel onPreviewReceived={handlePreviewReceived} onError={handleEditorError}></EditPanel>}
        preview={<PreviewPanel preview={preview} />}
        previewHidden={previewHidden}
      />
      <SnackBar message={message} />
      <HelpModal />
    </FrameTemplate>
  );
};
