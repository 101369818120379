import { useEffect } from "react";

export const useAsyncEffect = (effect, destroy, inputs) => {
  var hasDestroy = typeof destroy === "function";

  useEffect(
    function () {
      var result;
      var mounted = true;
      var maybePromise = effect(function () {
        return mounted;
      });

      Promise.resolve(maybePromise).then(function (value) {
        result = value;
      });

      return function () {
        mounted = false;

        if (hasDestroy) {
          destroy(result);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    hasDestroy ? inputs : destroy
  );
};
