import { Box, Divider, fade, Hidden, makeStyles, Menu } from "@material-ui/core";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import GetAppIcon from "@material-ui/icons/GetApp";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import RedoIcon from "@material-ui/icons/Redo";
import UndoIcon from "@material-ui/icons/Undo";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import React, { FC, useCallback, useState } from "react";
import { DropZone } from "../../atoms/drop-zone/DropZone";
import { ProgressMenuItem } from "../../atoms/progress-menu-item/ProgressMenuItem";
import { TooltipIconButton } from "../../atoms/tooltip-icon-button/TooltipIconButton";
import { SearchField } from "../../molecules/search-field/SearchField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    background: fade(theme.palette.primary.main, 0.0),
    flexWrap: "nowrap",
  },
  divider: {
    height: 28,
    margin: 4,
  },
  search: {
    flexGrow: 1,
  },
}));
type Props = {
  onSearch: (value: string) => void;
  onFindNext: () => void;
  onFindPrevious: () => void;
  onDownload: () => void;
  onPDFDownload: () => Promise<any>;
  onUndo: () => void;
  onRedo: () => void;
  onOpen: <T extends File>(acceptedFiles: T[]) => void;
  onCreate: () => void;
  onTogglePreviewVisibility: () => void;
  downloadDisabled: boolean;
  previewHidden?: boolean;
};

export const EditorToolbar: FC<Props> = ({
  onSearch,
  onFindNext,
  onFindPrevious,
  onDownload,
  onPDFDownload,
  onUndo,
  onRedo,
  onOpen,
  onCreate,
  onTogglePreviewVisibility,
  downloadDisabled,
  previewHidden,
}) => {
  const classes = useStyles();
  const [downloadAnchorEl, setDownloadAnchorEl] = useState<null | HTMLElement>(null);

  const handleSearch = useCallback(
    (value) => {
      onSearch(value);
    },
    [onSearch]
  );

  const handleFindNext = useCallback(() => {
    onFindNext();
  }, [onFindNext]);

  const handleFindPrevious = useCallback(() => {
    onFindPrevious();
  }, [onFindPrevious]);

  const handleUndo = useCallback(() => {
    onUndo();
  }, [onUndo]);

  const handleRedo = useCallback(() => {
    onRedo();
  }, [onRedo]);

  const handleDownload = useCallback(() => {
    onDownload();
    setDownloadAnchorEl(null);
  }, [onDownload]);

  const handlePDFDownload = useCallback(() => {
    return onPDFDownload().then(() => {
      setDownloadAnchorEl(null);
    });
  }, [onPDFDownload]);

  const handleCreate = useCallback(() => {
    onCreate();
  }, [onCreate]);

  const handleToggleFullScreen = useCallback(() => {
    onTogglePreviewVisibility();
  }, [onTogglePreviewVisibility]);

  const openDownloadMenu = useCallback((event) => {
    setDownloadAnchorEl(event.currentTarget);
  }, []);

  return (
    <Box className={classes.root}>
      <DropZone noDrag noClick onDrop={onOpen} accept={".yaml, .yml"}>
        {({ open }) => <TooltipIconButton tooltip="Open" onClick={open} icon={<FolderOpenIcon />} />}
      </DropZone>
      <TooltipIconButton tooltip="New (from template)" onClick={handleCreate} icon={<NoteAddOutlinedIcon />} />
      <Hidden mdDown>
        <Divider className={classes.divider} orientation="vertical" />
        <TooltipIconButton tooltip="Undo" onClick={handleUndo} icon={<UndoIcon />} />
        <TooltipIconButton tooltip="Redo" onClick={handleRedo} icon={<RedoIcon />} />
        <Divider className={classes.divider} orientation="vertical" />
        <div className={classes.search}>
          <SearchField onSearch={handleSearch} onFindNext={handleFindNext} onFindPrevious={handleFindPrevious} />
        </div>
      </Hidden>
      <Divider className={classes.divider} orientation="vertical" />
      <TooltipIconButton tooltip="Download" onClick={openDownloadMenu} icon={<GetAppIcon />} disabled={downloadDisabled} />
      <Menu
        anchorEl={downloadAnchorEl}
        open={Boolean(downloadAnchorEl)}
        onClose={() => {
          setDownloadAnchorEl(null);
        }}
      >
        <ProgressMenuItem onClick={handlePDFDownload} icon={<GetAppIcon />} text="PDF" />
        <ProgressMenuItem onClick={handleDownload} icon={<GetAppIcon />} text="YAML" />
      </Menu>
      <TooltipIconButton
        tooltip={previewHidden ? "Show preview" : "Hide preview"}
        onClick={handleToggleFullScreen}
        icon={previewHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
      />
    </Box>
  );
};
