import { useCallback } from "react";
import { useLocalStore } from "./useLocalStore";

export const useUserPreference = (preferenceName: string) => {
  const { getItem, setItem } = useLocalStore();

  const savePreference = useCallback(
    (value: any) => {
      return setItem(preferenceName, value);
    },
    [setItem, preferenceName]
  );

  const getPreference = useCallback(() => {
    return getItem(preferenceName);
  }, [getItem, preferenceName]);

  return {
    getPreference,
    savePreference,
  };
};

export const PreferenceNames = {
  WELCOME_MODAL_VIEWED: "WELCOME_MODAL_VIEWED",
};
