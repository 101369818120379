import { Fade, Grid, makeStyles, Paper } from "@material-ui/core";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useState } from "react";
import "react-ace";
import { postCVYaml } from "../../../api/cv";
import { useAsyncEffect } from "../../../hooks/useAsyncEffect";
import { useCV } from "../../../hooks/useCV";
import { useDownloadFile } from "../../../hooks/useDownloadFile";
import { DefaultTabs } from "../../molecules/tabs/DefaultTabs";
import { CVEngineControlForm } from "../cv-engine-control-form/CVEngineControlForm";
import { EditorContainer } from "../editor-container/EditorContainer";
import { PortraitForm } from "../portrait-form/PortraitForm";

const CVResultFormat = {
  HTML: "html",
  PDF: "pdf",
};

export const EditPanel = ({ onPreviewReceived, onError }) => {
  const initialEditorValue = "Open a CV in YAML format using the toolbar above or drag it on top of the editor.";
  const [editorValue, setEditorValue] = useState();
  const [controlFormValues, setControlFormValues] = useState({
    templateName: "contrivance",
    skillGradeThreshold: 3,
    includeSales: false,
    includeSocialLinks: true,
  });
  const [portraitFile, setPortraitFile] = useState();
  const { download } = useDownloadFile();
  const { getCV, saveCV } = useCV();

  const postYaml = useCallback(
    (value, resultFormat) => {
      return postCVYaml(value, portraitFile, {
        resultFormat,
        ...controlFormValues,
      });
    },
    [controlFormValues, portraitFile]
  );

  const generateHTML = useCallback(
    (value) => {
      postYaml(value, CVResultFormat.HTML)
        .then((res) => onPreviewReceived(res))
        .catch((e) => {
          onError({
            content: `Something went wrong while generating HTML preview - "${e.message}"`,
            severity: "error",
          });
        });
    },
    [onPreviewReceived, postYaml, onError]
  );

  const generatePDF = useCallback(
    (value) => {
      return postYaml(value, CVResultFormat.PDF)
        .then((res) => {
          download(new Blob([res]), "cv_pdf.pdf");
        })
        .catch((e) => {
          onError({
            content: `Something went wrong while generating PDF - "${e.message}"`,
            severity: "error",
          });
        });
    },
    [postYaml, onError, download]
  );

  const handleEditorChange = useCallback(
    (value) => {
      setEditorValue(value);
      (async () => {
        try {
          await saveCV(value);
        } catch (error) {
          console.error(error);
          onError({
            content: `Something went wrong while saving CV locally - "${error.message}"`,
            severity: "error",
          });
        }
      })();
    },
    [saveCV, onError]
  );

  const handlePDFDownload = useCallback(() => {
    return generatePDF(editorValue);
  }, [generatePDF, editorValue]);

  const handleControlFormChange = useCallback((formValues) => {
    setControlFormValues(formValues);
  }, []);

  const handlePortraitAdded = useCallback(
    (file) => {
      setPortraitFile(file);
    },
    [setPortraitFile]
  );

  const handlePortraitRemoved = useCallback(() => {
    setPortraitFile();
  }, [setPortraitFile]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const generateHTMLDebounced = useCallback(
    debounce((value) => generateHTML(value), 1000),
    [generateHTML]
  );

  useEffect(() => {
    if (editorValue) {
      generateHTMLDebounced(editorValue);
    }
  }, [generateHTMLDebounced, editorValue, controlFormValues]);

  useAsyncEffect(async (isMounted) => {
    try {
      const cv = await getCV();
      isMounted() && handleEditorChange(cv);
    } catch (error) {
      console.error(error);
      onError({
        content: `Something went wrong while reading local CV copy - "${error.message}"`,
        severity: "error",
      });
    }
  }, []);

  return (
    <Fade in timeout={1000}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={2}>
            <DefaultTabs>
              <TabPanelContainer tabLabel="YAML">
                <EditorContainer
                  onEditorChange={handleEditorChange}
                  editorValue={editorValue}
                  editorPlaceholder={initialEditorValue}
                  onPDFDownload={handlePDFDownload}
                />
              </TabPanelContainer>
              <TabPanelContainer padded tabLabel="Layout">
                <CVEngineControlForm values={controlFormValues} onChange={handleControlFormChange} />
              </TabPanelContainer>
              <TabPanelContainer padded tabLabel="Portrait">
                <PortraitForm portraitFile={portraitFile} onPortraitAdded={handlePortraitAdded} onPortraitRemoved={handlePortraitRemoved}></PortraitForm>
              </TabPanelContainer>
            </DefaultTabs>
          </Paper>
        </Grid>
      </Grid>
    </Fade>
  );
};

const useStyles = makeStyles((theme) => ({
  paddedTabPanel: {
    padding: theme.spacing(2),
  },
}));

const TabPanelContainer = ({ padded, children }) => {
  const classes = useStyles();
  return <div className={padded ? classes.paddedTabPanel : ""}>{children}</div>;
};
