import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  typography: {
    fontFamily: "UniversalSans",
  },
  palette: {
    primary: {
      light: "#D2A0FA",
      main: "#D2A0FA",
      dark: "#D2A0FA",
      contrastText: "#14140F",
    },
    secondary: {
      light: "#FF7D00",
      main: "#FF7D00",
      dark: "#FF7D00",
    },
    background: {
      default: "#FAFAFA",
    },
    text: {
      primary: "#14140F",
    },
  },
});
