import { AppBar, makeStyles, Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  tab: {
    color: theme.palette.text.primary
  },
  tabPanel: {
    padding: theme.spacing(0),
  },
}));

export const DefaultTabs = ({ children }) => {
  const [value, setValue] = useState("0");
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const childrenArray = React.Children.toArray(children);
  return (
    <TabContext value={value}>
      <AppBar position="static" color="default" elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          className={classes.tab}
          aria-label="tab"
          variant="fullWidth"
        >
          {childrenArray.map((child, index) => {
            return (
              <Tab
                label={child.props.tabLabel}
                value={index + ""}
                variant="fullWidth"
                key={child.props.tabLabel}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {childrenArray.map((child, index) => {
        return (
          <TabPanel value={index + ""} className={classes.tabPanel} key={index}>
            {child}
          </TabPanel>
        );
      })}
    </TabContext>
  );
};
