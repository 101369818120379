import localForage from "localforage";

export const useLocalStore = () => {
  const instance = localForage.createInstance({
    name: "cvDB",
  });

  return {
    getItem: instance.getItem,
    setItem: instance.setItem,
    removeItem: instance.removeItem,
    clear: instance.clear,
  };
};
