import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React, { FC, useCallback } from "react";

type Props = {
  title: string;
  isOpen: boolean;
  onClose: (event: any) => void;
  fullScreen?: boolean;
  contentClassName?: string;
  closeButtonText?: string;
};

export const Dialog: FC<Props> = ({ title, closeButtonText = "Close", isOpen, fullScreen, onClose, contentClassName, children }) => {
  const handleClose = useCallback(
    (event) => {
      onClose(event);
    },
    [onClose]
  );
  return (
    <MuiDialog open={isOpen} onClose={handleClose} scroll="paper" fullScreen={fullScreen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent classes={{ root: contentClassName }} dividers>{children}</DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>
          {closeButtonText}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};
