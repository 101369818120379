import { Ace } from "ace-builds";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-clouds";
import React, { FC } from "react";
import AceEditor from "react-ace";

type Props = {
  onChange: (value: string) => void;
  mode: string;
  theme: string;
  name: string;
  value: string;
  width: string;
  height: string;
  wrapEnabled: boolean;
  showPrintMargin: boolean;
  tabSize: number;
  placeholder: string;
  onEditorLoaded: (editor: Ace.Editor) => void;
};

export const TextEditor: FC<Props> = ({
  onChange,
  mode,
  theme,
  name,
  value,
  width,
  height,
  wrapEnabled,
  showPrintMargin,
  tabSize,
  placeholder,
  onEditorLoaded,
}) => {
  const handleLoad = (editorInstance: Ace.Editor) => {
    onEditorLoaded(editorInstance);
  };

  return (
    <>
      <AceEditor
        onLoad={handleLoad}
        mode={mode}
        theme={theme}
        onChange={onChange}
        name={name}
        value={value}
        width={width}
        height={height}
        wrapEnabled={wrapEnabled}
        showPrintMargin={showPrintMargin}
        tabSize={tabSize}
        placeholder={placeholder}
      ></AceEditor>
    </>
  );
};
