export type ModalId = string;

export type ModalContextValues = {
  state: ModalState;
  dispatch: React.Dispatch<ModalActionTypes>;
};

export interface Modal {
  id: ModalId;
  isOpen?: boolean;
}
export type ModalRecord = Record<ModalId, Modal>;
export type ModalState = ModalRecord;

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export interface ShowModalAction {
  type: typeof SHOW_MODAL;
  payload: Modal;
}

export interface HideModalAction {
  type: typeof HIDE_MODAL;
  payload: Modal;
}

export type ModalActionTypes = ShowModalAction | HideModalAction;

//export type ModalTypes = Record<>
