import { Box, Tooltip, Zoom } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import SearchIcon from "@material-ui/icons/Search";
import React, { useCallback, useEffect, useState } from "react";
import { InputText } from "../../atoms/input-text/InputText";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  },
  iconButton: {
    padding: 5,
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const SearchField = ({ onSearch, onFindNext, onFindPrevious }) => {
  const classes = useStyles();
  const [value, setValue] = useState();
  const handleValueChange = useCallback(
    ({ value }) => {
      setValue(value);
    },
    [setValue]
  );

  const handleKeyUp = useCallback(
    ({ keyCode }) => {
      switch (keyCode) {
        case 13:
        case 40:
          // pressing Enter or Down Arrow will find the next match
          onFindNext();
          break;
        case 38:
          // pressing Up Arrow will find the previous match
          onFindPrevious();
          break;
        default: {
          console.log(`Keycode ${keyCode} not interpretted`);
        }
      }
    },
    [onFindNext, onFindPrevious]
  );

  useEffect(() => {
    onSearch(value);
  }, [value, onSearch]);

  return (
    <Box className={classes.root}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <div className={classes.input}>
        <InputText
          placeholder="Find..."
          className={classes.input}
          value={value}
          onChange={handleValueChange}
          fullWidth
          disableUnderline
          onKeyUp={handleKeyUp}
        />
      </div>
      <SearchTools onFindNext={onFindNext} onFindPrevious={onFindPrevious} visible={!!value} />
    </Box>
  );
};

const SearchTools = ({ onFindNext, onFindPrevious, visible }) => {
  const classes = useStyles();
  return (
    <>
      <Zoom in={visible}>
        <Tooltip title="Find previous">
          <IconButton type="submit" className={classes.iconButton} aria-label="find previous" onClick={onFindPrevious}>
            <KeyboardArrowUpIcon />
          </IconButton>
        </Tooltip>
      </Zoom>
      <Zoom in={visible}>
        <Tooltip title="Find next">
          <IconButton type="submit" className={classes.iconButton} aria-label="find next" onClick={onFindNext}>
            <KeyboardArrowDownIcon />
          </IconButton>
        </Tooltip>
      </Zoom>
    </>
  );
};
