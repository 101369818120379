import { makeStyles, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { NumberedBullet } from "../../atoms/numbered-bullet/NumberedBullet";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  bullet: {
    flex: "0 1 auto",
  },
  contentContainer: {
    marginLeft: theme.spacing(3),
    color: theme.palette.text.primary,
  },
}));

type Props = {
  index: number;
};

export const NumberedListItem: FC<Props> = ({ index, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.bullet}>
        <NumberedBullet index={index} />
      </div>
      <Typography variant="body2" className={classes.contentContainer}>
        {children}
      </Typography>
    </div>
  );
};
